import Header from 'header'
import Main from 'main'
import Footer from 'footer'


export default function App() {
    return <>
        <Header/>
        <Main/>
        <Footer/>
    </>
}
