import Copyright from 'component/copyright'

const copyright = {
    left: `© WILHELM V`,
    middle: `O`,
    right: `N HABSBURG 2024`,
}

// const copyright = {
//     left: process.env.REACT_APP__NAME_LEFT,
//     middle: process.env.REACT_APP__NAME_MIDDLE === `` ? ` ` : process.env.REACT_APP__NAME_MIDDLE,
//     right: process.env.REACT_APP__NAME_RIGHT,
// }

export default function Footer() {
    return <>
        <footer id="footer" className="footer">
            <Copyright text={ copyright }/>
        </footer>
    </>
}
