import Title from './title'
import About from './about'
import Portfolio from './portfolio'
import Contact from './contact'

export default function Main() {
    return <>
        <main id="main" className="main">
            <Title/>
            <About/>
            <Portfolio/>
            <Contact/>
        </main>
    </>
}
