import { useRef, useEffect } from 'react'

export default function Copyright({
    text,
}) {
    const paragraph = useRef()
    const left = useRef()
    const right = useRef()
    const oLeft = useRef()
    const oRight = useRef()

    useEffect(_ => {
        const observer = new ResizeObserver(entries => {
            if (oLeft.current && left.current) {
                left.current.style.right = `-${ oLeft.current.offsetWidth / 2 }px`
            }
            if (oRight.current && right.current) {
                right.current.style.left = `-${ oRight.current.offsetWidth / 2 }px`
            }
        })
        observer.observe(paragraph.current)
    }, [ ])

    return (
        <p
            ref={ paragraph }
            className="copyright"
        >
            <span className="copyright__base copyright__left_base">
                <span
                    ref={ left }
                    className="copyright__left_name"
                >
                    <span>{ text.left }</span>
                    <span
                        ref={ oLeft }
                        className="copyright__left__name_split"
                    >
                        { text.middle }
                    </span>
                </span>
            </span>
            <span className="copyright__base copyright__right_base">
                <span
                    ref={ right }
                    className="copyright__right_name"
                >
                    <span
                        ref={ oRight }
                        className="copyright__right__name_split"
                    >
                        { text.middle }
                    </span>
                    <span>{ text.right }</span>
                </span>
            </span>
        </p>
    )
}
