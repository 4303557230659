function Project({
    img,
    title,
    list_tool,
    list_feature,
    source,
    view,
    wide = false,
}) {
    return <>
    <article className={ `project ${ wide === true ? `project--wide` : `` }` }>
        <img className="project__thumbnail" src={ img } alt="background"/>
        <div className="project__content">
            <h3 className="project__title">{ title }</h3>
            <ul className="project__list">
                { list_tool.map(tool => <li key={ tool.toLowerCase().trim().replaceAll(` `, `-`) } className="project__list_item">{ tool }</li>) }
            </ul>
            <ul className="project__list">
                { list_feature.map(feature => <li key={ feature } className="project__list_item">{ feature }</li>) }
            </ul>
            <nav className="project__menu">
                { source && <a className="project__menu_link" href={ source }><span className="project__menu_link_text"><i className="fab fa-git"/></span></a> }
                { view && <a className="project__menu_link" href={ view }><span className="project__menu_link_text"><i className="fas fa-eye"/></span></a> }
            </nav>
        </div>
    </article>
    </>
}


const project = {}
project.commerce = <>
    <Project
        img="/file/image/abstract/min/abstract4.jpg"
        title="Commerce"
        list_tool={ [
            `SQL`,
            `Java`,
            `Spring`,
            `Rest`,
            `React`,
            `Sass`,
        ] }
        list_feature={ [
            `accounts`,
            `catalogue`,
            `search`,
            `scores`,
            `reviews`,
            `payments`,
        ] }
        view="https://wilhelmvonhabsburg.com/commerce/"
        wide={ true }
    />
</>

project.filesystem = <>
    <Project
        img="/file/image/abstract/min/abstract5.jpg"
        title="File System"
        list_tool={ [
            `SQL`,
            `Rust`,
            `Bash`,
            `Rsync`,
        ] }
        list_feature={ [
            `backup`,
            `search`,
        ] }
        // view="https://zarimirmitev.com/commerce/"
    />
</>

project.limpio = <>
    <Project
        img="/file/image/abstract/min/abstract2.jpg"
        title="Limpio"
        list_tool={ [
            `Express.js`,
            `React.js`,
            `Sass`,
        ] }
        list_feature={ [
            `translation`,
            `contact`,
        ] }
        view="https://limpiogroup.eu"
    />
</>


export default function Porfolio() {
    return <>
        <section id="portfolio" className="section">
            <header className="section_header">
                <h2 className="section_header__title">PORTFOLIO</h2>
            </header>
            <div className="project_list">
                { project.commerce }
                { project.filesystem }
                { project.limpio }
            </div>
        </section>
    </>
}
