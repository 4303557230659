function Skillset({
    icon,
    title,
    list_skill,
}) {
    return <>
    <article className="skillset">
        <p className="skillset__icon"><i className={ icon }/></p>
        <h1 className="skillset__title">{ title }</h1>
        <ul className="skillset__list">
            { list_skill.map(skill => <li key={ skill.toLowerCase().trim().replaceAll(` `, `-`) } className="skillset__list_item">{ skill }</li>) }
        </ul>
    </article>
    </>
}

const skillset = {}
skillset.teaching = <>
    <Skillset
        icon="fas fa-graduation-cap"
        title="Teaching"
        list_skill={ [
            `Probability Theory`,
            `Graph Theory`,
            `Theory of Computation`,
            `Linear Algebra`,
            `Abstract Algebra`,
        ] }
    />
</>

skillset.backend = <>
    <Skillset
        icon="fas fa-tools"
        title="Backend"
        list_skill={ [
            `Java Spring`,
            `C# ASP.NET`,
            `Python Django`,
            `Node Express`,
            `SQL`,
        ] }
    />
</>

skillset.frontend = <>
    <Skillset
        icon="fas fa-pencil-ruler"
        title="Frontend"
        list_skill={ [
            `React`,
            `Angular`,
            `Vue`,
            `Bootstrap`,
            `Tailwind`,
        ] }
    />
</>


skillset.programming = <>
    <Skillset
        icon="fas fa-tools"
        title="Programming"
        list_skill={ [
            `SQL`,
            `Java`,
            `Spring`,
            `React`,
            `Rust`,
        ] }
    />
</>

export default skillset
