import { HashLink } from 'react-router-hash-link'

import skillset from 'component/skillset'

export default function About() {
    return <>
        <section id="about" className="section">
            <header className="section_header">
                <h2 className="section_header__title">About</h2>
                <p className="section_header__subtitle">I'm a mathematician with a CS degree and eight years of experience teaching mathematics and computer science to university students. Studying at an international university has taught me to be organised, adaptable and to thrive in a dynamic environment.</p>
                <nav className="section_header__navigation">
                    <HashLink className="section_header__navigation_link" to="/page/home#portfolio" smooth>Portfolio</HashLink>
                    <a className="section_header__navigation_link" href={ `${ process.env.PUBLIC_URL }/file/pdf/resume.pdf` }>Resume</a>
                    <HashLink className="section_header__navigation_link" to="/page/home#contact" smooth>Contact</HashLink>
                </nav>
            </header>
            <section className="skillset_list">
                { skillset.teaching }
                { skillset.programming }
            </section>
        </section>
    </>
}
