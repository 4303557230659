import { useState, useEffect } from 'react'
import { HashLink } from 'react-router-hash-link'

const screen_lg = 790

export default function Header() {
    const [ toggle, update_toggle ] = useState(false)
    const [ mode, update_mode ] = useState(window.innerWidth >= screen_lg ? `large` : `small`)
    const home = mode === `large` ? `WILHELM VON HABSBURG` : `WvH`

    const close = _ => update_toggle(false)
    const open = _ => update_toggle(!toggle)

    useEffect(_ => {
        const resize = _ => {
            if (window.innerWidth >= screen_lg) {
                update_mode(`large`)
                update_toggle(false)
            } else {
                update_mode(`small`)
            }
        }
        window.addEventListener(`resize`, resize)
        return _ => window.removeEventListener(`resize`, resize)
    }, [ ])

    return <>
        <header id="header" className="header">
            <nav className="menu_home">
                <HashLink className="menu_home__link" to="#main" smooth>{ home }</HashLink>
            </nav>
            <nav className={ `menu_main_lg ${ toggle ? `colored` : `` }` }>
                <HashLink className="menu_main_lg__link menu_main_lg__link--main" to="#about" smooth>About</HashLink>
                <HashLink className="menu_main_lg__link menu_main_lg__link--main" to="#portfolio" smooth>Portfolio</HashLink>
                <a className="menu_main_lg__link menu_main_lg__link--main" href="/file/pdf/resume.pdf">Resume</a>
                <HashLink className="menu_main_lg__link menu_main_lg__link--main" to="#contact" smooth>Contact</HashLink>
                <button className="menu_main_lg__link menu_main_lg__link--alt" onClick={ open }><i className={ toggle ? `far fa-window-close` : `fas fa-bars` }/></button>
            </nav>
            <nav className={ `menu_main_sm ${ toggle ? `` : `collapsed` }` }>
                <HashLink className={ `menu_main_sm__link ${ toggle ? `` : `menu_main_sm__link--collapsed` }` } onClick={ close } to="#about" smooth>About</HashLink>
                <HashLink className={ `menu_main_sm__link ${ toggle ? `` : `menu_main_sm__link--collapsed` }` } onClick={ close }  to="#portfolio" smooth>Portfolio</HashLink>
                <a className={ `menu_main_sm__link ${ toggle ? `` : `menu_main_sm__link--collapsed` }` } onClick={ close } href={ `${ process.env.PUBLIC_URL }/file/pdf/resume.pdf` }>Resume</a>
                <HashLink className={ `menu_main_sm__link ${ toggle ? `` : `menu_main_sm__link--collapsed` }` } onClick={ close }  to="#contact" smooth>Contact</HashLink>
            </nav>
        </header>
    </>
}
